import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import image from '../../assets/images/app/image.png'
import { TypeAnimation } from 'react-type-animation';

import Navbar from '../../component/Navbar/navbar';
import Footer from '../../component/Footer/footer';
import CompanyLogo from '../../component/companyLogo';
import CookieModal from '../../component/cookieModal';
import IntegrationAnimation from '../../component/Pages/index-ai/IntegrationAnimation';
import ChainwideAnimation from '../../assets/gif/chainwide.gif'

import {FaRegEnvelope, MdKeyboardArrowRight,FaArrowRight} from '../../assets/icons/icons'

export default function IndexAi(){
    const [activeIndex, setActiveIndex] = useState(0);
   
    const toggleAccordion = (index) => {
        if (activeIndex === index) {
            setActiveIndex(0);
        } else {
            setActiveIndex(index);
        }
    }

    useEffect(() => {
        const htmlTag = document.getElementsByTagName("html")[0]
        htmlTag.classList.add('dark');
        htmlTag.classList.remove('light')

    });
    return(
        <>
         <span className="fixed blur-[200px] lg:w-[600px] w-[400px] lg:h-[600px] h-[400px] rounded-full top-[10%] md:start-[10%] -start-[20%] bg-indigo-600/20"></span>
        <span className="fixed blur-[200px] lg:w-[600px] w-[400px] lg:h-[600px] h-[400px] rounded-full bottom-[10%] md:end-[10%] -end-[20%] bg-red-600/20"></span>
        <Navbar/>
        <section className="relative table w-full lg:py-40 md:py-36 pt-36 pb-24 overflow-hidden bg-white dark:bg-slate-900">
            <div className="absolute inset-0 bg-[url('../../assets/images/overlay.png')] bg-repeat opacity-10 dark:opacity-60"></div>
            <div className="container relative z-1">
                <div className="relative grid lg:grid-cols-12 grid-cols-1 items-center mt-10 gap-[30px]">
                <div className="lg:col-span-7">
                    <div className="lg:me-6 lg:text-start text-center">
                    <h1 className="font-bold lg:leading-normal leading-normal text-4xl lg:text-6xl mb-5">
                        We are the Middleware <br />
                        For 
                        <TypeAnimation
                        sequence={[
                            ' Your Customer Integration',
                            2000,
                            ' RAG Agents',
                            2000,
                            ' Streamlining Workflows',
                            2000,
                        ]}
                        wrapper="span"
                        speed={50}
                        className="typewrite bg-gradient-to-tl to-indigo-600 from-red-600 text-transparent bg-clip-text"
                        repeat={Infinity}
                        />
                    </h1>
                    <p className="text-lg max-w-xl lg:ms-0 mx-auto">
                        Integrate agents with datasources to address your customers' needs—FAQs, knowledge bases, summarization, and more—while reducing complexity and accelerating results—all with simple API calls.
                    </p>

                    {/* Buttons for See Docs and Get Started */}
                    <div className="mt-6 flex items-center justify-center lg:justify-start gap-4">
                        <li className="inline-block m-0.5">
                            <Link to="" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-transparent hover:bg-sky-500 border-sky-500 text-sky-500 hover:text-white rounded-md">Check Docs</Link>
                        </li>
                        <li className="inline-block m-0.5">
                            <Link to="" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white rounded-md">Get Started</Link>
                        </li>
                    </div>

                    </div>
                </div>

                <div className="lg:col-span-5 relative">
                    <div className="relative after:content-[''] after:absolute lg:after:-top-0 after:-top-10 after:-right-32 after:w-[36rem] after:h-[36rem] after:border-2 after:border-dashed after:border-slate-200 dark:after:border-slate-700 after:rounded-full after:animate-[spin_120s_linear_infinite] after:-z-1 before:content-[''] before:absolute lg:before:-top-24 before:-top-36 before:-right-56 before:w-[48rem] before:h-[48rem] before:border-2 before:border-dashed before:border-slate-200 dark:before:border-slate-700 before:rounded-full before:animate-[spin_240s_linear_infinite] before:-z-1">
                    <div className="relative after:content-[''] after:absolute lg:after:-top-0 after:-top-10 after:-right-40 after:w-[36rem] after:h-[36rem] after:bg-gradient-to-tl after:to-indigo-600/30 after:from-red-600/30 dark:after:to-indigo-600/50 dark:after:from-red-600/50 after: after:blur-[200px] after:rounded-full after:-z-1">
                        <img src={image} className="lg:max-w-none lg:ms-14" alt=""/>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </section>



          <section className="pt-6 flex flex-col items-center justify-center">
          <p className="text-sm text-slate-400 mt-4">Integrate with</p>
            <div className="container relative">
                <CompanyLogo/>
            </div>
            <p className="text-sm text-slate-400 mt-4">and 1000+ connectors</p>
        </section>
        <section className="pt-6 flex flex-col items-center justify-center">
          <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">How Chainwide RAG agents fit in</h3>
          <p> We do integration heavy lifting so you dont have to</p>
          <img src={ChainwideAnimation} alt="Chainwide Animation" /> {/* Replace with an img tag */}
        </section>
        <section className="relative md:py-24 py-16">
            <div className="container relative">
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold text-center">How Chainwide Works</h3>
                <div className="grid md:grid-cols-3 grid-cols-1 shadow shadow-gray-300 dark:shadow-gray-500 rounded-lg md:divide-x-[1px] divide-y-[1px] divide-gray-200 dark:divide-gray-700">
                    <div className="relative p-6 md:p-8">
                        <i className="mdi mdi-account-search-outline bg-gradient-to-tl to-indigo-600 from-red-600 text-transparent bg-clip-text text-[45px]"></i>

                        <h5 className="text-xl font-semibold my-5">Connect Data Sources</h5>

                        <p className="text-slate-400">Use our API to connect your customers' data sources quickly and securely. Chainwide supports popular platforms like Salesforce and HubSpot, with more coming soon.</p>

                        <MdKeyboardArrowRight className="h-8 w-8 p-1 bg-indigo-600 text-white rounded-full flex justify-center items-center absolute md:top-2/4 md:-translate-y-2/4 -bottom-4 md:-end-0 end-2/4 ltr:translate-x-2/4 rtl:-translate-x-2/4 rtl:rotate-180 z-1"/>
                    </div>

                    <div className="relative p-6 md:p-8">
                        <i className="mdi mdi-wallet-bifold-outline bg-gradient-to-tl to-indigo-600 from-red-600 text-transparent bg-clip-text text-[45px]"></i>

                        <h5 className="text-xl font-semibold my-5">Activate AI with Ready-Made RAG Agents</h5>

                        <p className="text-slate-400">Select from a range of RAG agents to apply to each data source, whether for lead scoring, summarization, or other specialized functions. No machine learning expertise required.</p>

                        <MdKeyboardArrowRight className="h-8 w-8 p-1 bg-indigo-600 text-white rounded-full flex justify-center items-center absolute md:top-2/4 md:-translate-y-2/4 -bottom-4 md:-end-0 end-2/4 ltr:translate-x-2/4 rtl:-translate-x-2/4 rtl:rotate-180 z-1"/>
                    </div>

                    <div className="relative p-6 md:p-8">
                        <i className="mdi mdi-home-plus-outline bg-gradient-to-tl to-indigo-600 from-red-600 text-transparent bg-clip-text text-[45px]"></i>

                        <h5 className="text-xl font-semibold my-5">Access Real-Time Insight</h5>

                        <p className="text-slate-400">Send queries and receive context-aware responses instantly. Chainwide handles the heavy lifting so you can focus on building products your customers love.</p>
                    </div>
                </div>
            </div>
        
            <div className="container relative md:mt-24 mt-16">
                <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px] relative">
                    <div className="lg:col-span-8 md:col-span-12">
                        <div className="grid grid-cols-1 pb-8">
                            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Top Use Cases</h3>
                        </div>
                    
                        <div className="grid md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
                            <div className="px-6 py-10 shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 duration-500 rounded-lg hover:bg-white hover:dark:bg-slate-900">
                               
                                <span className="mdi mdi-flip-horizontal text-4xl bg-gradient-to-tl to-indigo-600 from-red-600 text-transparent bg-clip-text"></span>
        
                                <div className="content mt-7">
                                    <Link to="page-services.html" className="title h5 text-lg font-medium hover:text-indigo-600">Sales Enablement</Link>
                                    <p className="text-slate-400 mt-3"> We’ll integrate with your customers' CRM systems to deliver real-time insights, lead recommendations, and sales performance optimizations using AI-powered RAG agents.</p>
                                    
                                    <div className="mt-5">
                                        <Link to="page-services.html" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More  <FaArrowRight className="ms-2 text-[10px]"/></Link>
                                    </div>
                                </div>
                            </div>

                            <div className="px-6 py-10 shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 duration-500 rounded-lg hover:bg-white hover:dark:bg-slate-900">
                                <span className="mdi mdi-email-edit-outline text-4xl bg-gradient-to-tl to-indigo-600 from-red-600 text-transparent bg-clip-text"></span>
        
                                <div className="content mt-7">
                                    <Link to="page-services.html" className="title h5 text-lg font-medium hover:text-indigo-600">Customer Support & FAQ Automation</Link>
                                    <p className="text-slate-400 mt-3"> Our platform integrates with your customers' data sources to run RAG agents that automate FAQ responses, freeing up support teams for high-priority tasks.</p>
                                    
                                    <div className="mt-5">
                                        <Link to="page-services.html" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More  <FaArrowRight className="ms-2 text-[10px]"/></Link>
                                    </div>
                                </div>
                            </div>

                            <div className="px-6 py-10 shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 duration-500 rounded-lg hover:bg-white hover:dark:bg-slate-900">
                                <span className="mdi mdi-star-outline text-4xl bg-gradient-to-tl to-indigo-600 from-red-600 text-transparent bg-clip-text"></span>
                                <div className="content mt-7">
                                    <Link to="page-services.html" className="title h5 text-lg font-medium hover:text-indigo-600">Knowledge Base Management</Link>
                                    <p className="text-slate-400 mt-3">Integrate seamlessly with your customers’ knowledge management systems using an API key, and our RAG agents will keep their knowledge bases up-to-date, searchable, and accessible in real time.</p>
                                    
                                    <div className="mt-5">
                                        <Link to="page-services.html" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More  <FaArrowRight className="ms-2 text-[10px]"/></Link>
                                    </div>
                                </div>
                            </div>

                            <div className="px-6 py-10 shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 duration-500 rounded-lg hover:bg-white hover:dark:bg-slate-900">
                                <span className="mdi mdi-bookmark-outline text-4xl bg-gradient-to-tl to-indigo-600 from-red-600 text-transparent bg-clip-text"></span>
                                <div className="content mt-7">
                                    <Link to="page-services.html" className="title h5 text-lg font-medium hover:text-indigo-600">Summarization & Insights Generation</Link>
                                    <p className="text-slate-400 mt-3">We pull data from your customers' environments to generate concise, AI-driven summaries of documents and interactions, providing actionable insights at scale.</p>
                                    
                                    <div className="mt-5">
                                        <Link to="page-services.html" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More  <FaArrowRight className="ms-2 text-[10px]"/></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="lg:col-span-4 md:col-span-12">
                        <div className="grid lg:grid-cols-1 md:grid-cols-2 grid-cols-1 gap-[30px]">
                            <div className="px-6 py-10 shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 duration-500 rounded-lg hover:bg-white hover:dark:bg-slate-900">
                                <span className="mdi mdi-account-check-outline text-4xl bg-gradient-to-tl to-indigo-600 from-red-600 text-transparent bg-clip-text"></span>
                                <div className="content mt-7">
                                    <Link to="page-services.html" className="title h5 text-lg font-medium hover:text-indigo-600">Real-Time Performance Monitoring</Link>
                                    <p className="text-slate-400 mt-3"> We integrate with your customers' platforms to monitor key performance metrics, delivering real-time insights and running RAG agents to suggest optimizations.</p>
                                    
                                    <div className="mt-5">
                                        <Link to="page-services.html" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More  <FaArrowRight className="ms-2 text-[10px]"/></Link>
                                    </div>
                                </div>
                            </div>

                            <div className="px-6 py-10 shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 duration-500 rounded-lg hover:bg-white hover:dark:bg-slate-900">
                                <span className="mdi mdi-comment-multiple-outline text-4xl bg-gradient-to-tl to-indigo-600 from-red-600 text-transparent bg-clip-text"></span>
                                <div className="content mt-7">
                                    <Link to="page-services.html" className="title h5 text-lg font-medium hover:text-indigo-600">Custom AI Solutions for Unique Business Needs</Link>
                                    <p className="text-slate-400 mt-3"> We’ll integrate with any customer system to run bespoke RAG agents tailored to their specific use cases, from customer engagement to operational improvements.</p>
                                    
                                    <div className="mt-5">
                                        <Link to="page-services.html" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More  <FaArrowRight className="ms-2 text-[10px]"/></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
          <Footer/>
          <CookieModal/>
        </>
    )
}