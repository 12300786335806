import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './assets/libs/@mdi/font/css/materialdesignicons.min.css';
import Switch from './component/Switch';
import IndexAi from './pages/index/index-ai';
import HealthcareUseCase from './pages/usecase/healthcare';

export default function App() {
    return (
        <BrowserRouter>
            <Switch />
            <Routes>
                <Route path="/" element={<IndexAi />} />
                <Route path="/usecase-healthcare" element={<HealthcareUseCase />} />
            </Routes>
        </BrowserRouter>
    );
}
