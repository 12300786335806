import React from 'react';
import hubspotLogo from '../../../assets/images/client/hubspot.png';
import salesforceLogo from '../../../assets/images/client/salesforce.png';
import gongLogo from '../../../assets/images/client/gong.png';
import chorusLogo from '../../../assets/images/client/chorus.png';
import dialpadLogo from '../../../assets/images/client/dialpad.png';
import ringcentralLogo from '../../../assets/images/client/apollo.png';
import './IntegrationAnimation.css';  // CSS for the animation

const IntegrationAnimation = () => {
  return (
    <div className="integration-animation">
      <svg width="600" height="400" xmlns="http://www.w3.org/2000/svg">
        {/* HubSpot Logo */}
        <g className="logo-group">
          <circle cx="50" cy="50" r="30" className="data-source" />
          <image href={hubspotLogo} x="25" y="25" height="50" width="50" />
        </g>

        {/* Salesforce Logo */}
        <g className="logo-group">
          <circle cx="50" cy="150" r="30" className="data-source" />
          <image href={salesforceLogo} x="25" y="125" height="50" width="50" />
        </g>

        {/* Gong Logo */}
        <g className="logo-group">
          <circle cx="50" cy="250" r="30" className="data-source" />
          <image href={gongLogo} x="25" y="225" height="50" width="50" />
        </g>

        {/* Chainwide Tool (Center) */}
        <g className="chainwide-group">
          <rect x="250" y="120" width="100" height="60" className="chainwide-tool" />
          <text x="300" y="155" fill="white" fontSize="12" textAnchor="middle">Chainwide</text>
        </g>

        {/* RAG Agent Logo */}
        <g className="rag-agent-group">
          <circle cx="450" cy="150" r="30" className="rag-agent" />
          <image href={ringcentralLogo} x="425" y="125" height="50" width="50" />
        </g>

        {/* Connecting Lines */}
        <line x1="80" y1="50" x2="250" y2="150" className="line" />
        <line x1="80" y1="150" x2="250" y2="150" className="line" />
        <line x1="80" y1="250" x2="250" y2="150" className="line" />
        <line x1="350" y1="150" x2="420" y2="150" className="line" />
      </svg>
    </div>
  );
};

export default IntegrationAnimation;
