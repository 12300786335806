// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.integration-animation {
    position: relative;
    width: 600px;
    height: 400px;
  }
  
  .data-source, .rag-agent, .chainwide-tool {
    fill: #4CAF50; /* Background for the circles and rectangle */
  }
  
  .chainwide-tool {
    fill: #3F51B5;
  }
  
  .rag-agent {
    fill: #FF5722;
  }
  
  /* Animation for lines (data flow) */
  .line {
    stroke: #333;
    stroke-width: 2;
    stroke-dasharray: 5;
    animation: dash 2s infinite ease-in-out;
  }
  
  /* Dashing effect for data flow lines */
  @keyframes dash {
    0% {
      stroke-dashoffset: 100;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/component/Pages/index-ai/IntegrationAnimation.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,aAAa;EACf;;EAEA;IACE,aAAa,EAAE,6CAA6C;EAC9D;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,aAAa;EACf;;EAEA,oCAAoC;EACpC;IACE,YAAY;IACZ,eAAe;IACf,mBAAmB;IACnB,uCAAuC;EACzC;;EAEA,uCAAuC;EACvC;IACE;MACE,sBAAsB;IACxB;IACA;MACE,oBAAoB;IACtB;EACF","sourcesContent":[".integration-animation {\n    position: relative;\n    width: 600px;\n    height: 400px;\n  }\n  \n  .data-source, .rag-agent, .chainwide-tool {\n    fill: #4CAF50; /* Background for the circles and rectangle */\n  }\n  \n  .chainwide-tool {\n    fill: #3F51B5;\n  }\n  \n  .rag-agent {\n    fill: #FF5722;\n  }\n  \n  /* Animation for lines (data flow) */\n  .line {\n    stroke: #333;\n    stroke-width: 2;\n    stroke-dasharray: 5;\n    animation: dash 2s infinite ease-in-out;\n  }\n  \n  /* Dashing effect for data flow lines */\n  @keyframes dash {\n    0% {\n      stroke-dashoffset: 100;\n    }\n    100% {\n      stroke-dashoffset: 0;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
