import React from 'react';
import logo from '../../assets/images/logo-light.png'; // Choose the logo you want to keep
import '../../assets/libs/@mdi/font/css/materialdesignicons.min.css';
import '../../assets/css/tailwind.css';
import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { CALENDLY_URL } from '../../constants';

export default function Navbar(props) {
    let { navClass, navJustify } = props;
    let [isMenu, setisMenu] = useState(false);
    let [menu, setMenu] = useState('');
    let location = useLocation();

    useEffect(() => {
        let current = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
        setMenu(current);

        function windowScroll() {
            const navbar = document.getElementById("topnav");
            if (
                document.body.scrollTop >= 50 ||
                document.documentElement.scrollTop >= 50
            ) {
                if (navbar !== null) {
                    navbar?.classList.add("nav-sticky");
                }
            } else {
                if (navbar !== null) {
                    navbar?.classList.remove("nav-sticky");
                }
            }
        }
        window.addEventListener("scroll", windowScroll);
        window.scrollTo(0, 0);
        return () => {
            window.removeEventListener('scroll', windowScroll);
        };

    }, [location.pathname.substring(location.pathname.lastIndexOf('/') + 1)]);

    const toggleMenu = () => {
        setisMenu(!isMenu);
        if (document.getElementById("navigation")) {
            const anchorArray = Array.from(document.getElementById("navigation").getElementsByTagName("a"));
            anchorArray.forEach(element => {
                element.addEventListener('click', (elem) => {
                    const target = elem.target.getAttribute("href");
                    if (target !== "") {
                        if (elem.target.nextElementSibling) {
                            var submenu = elem.target.nextElementSibling.nextElementSibling;
                            submenu.classList.toggle('open');
                        }
                    }
                });
            });
        }
    };

    return (
        <nav id="topnav" className={`defaultscroll ${navClass}`}>
            <div className="container relative">
                <Link className="logo" to="/">
                    <img src={logo} className="max-h-10 w-auto" alt="Logo" />
                </Link>
                <div className="menu-extras">
                    <div className="menu-item">
                        <Link to="#" className={`navbar-toggle ${isMenu ? 'open' : ''}`} id="isToggle" onClick={() => toggleMenu()}>
                            <div className="lines">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </Link>
                    </div>
                </div>
                <div id="navigation" style={{ display: isMenu ? 'block' : 'none' }}>
                    <ul className={`navigation-menu ${navClass} ${navJustify}`}>
                        <li className={menu === "index" || "" ? "active" : ""}><Link to="/" className="sub-menu-item">Home</Link></li>
                        <li className={menu === "contact-one" ? "active" : ""}>
                            <a href={CALENDLY_URL} className="sub-menu-item" target="_blank" rel="noopener noreferrer">Schedule Demo</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}
