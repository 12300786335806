import React from "react";
import hubspot from '../assets/images/client/hubspot.png';
import salesforce from '../assets/images/client/salesforce.png';
import gong from '../assets/images/client/gong.png';
import chorus from '../assets/images/client/chorus.png';
import dialpad from '../assets/images/client/dialpad.png';
import ringcentral from '../assets/images/client/apollo.png';
import jira from '../assets/images/jira.svg';
import onedrive from '../assets/images/onedrive.svg';

export default function CompanyLogo(){
    return(
        <div className="grid md:grid-cols-6 grid-cols-2 justify-center gap-[30px] items-center">
            <div className="mx-auto py-4">
                <img src={hubspot} className="h-10 w-auto" alt="HubSpot Logo" />
            </div>

            <div className="mx-auto py-4">
                <img src={salesforce} className="h-10 w-auto" alt="Salesforce Logo" />
            </div>

            <div className="mx-auto py-4">
                <img src={gong} className="h-10 w-auto" alt="Gong Logo" />
            </div>

            <div className="mx-auto py-4">
                <img src={onedrive} className="h-10 w-auto" alt="Onedrive Logo" />
            </div>

            <div className="mx-auto py-4">
                <img src={dialpad} className="h-10 w-auto" alt="Dialpad Logo" />
            </div>

            <div className="mx-auto py-4">
                <img src={jira} className="h-10 w-auto" alt="Notion Logo" />
            </div>
            
        </div>
    )
}
